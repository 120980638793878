import seo from 'next-seo.config'
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo'
import { config } from '@fortawesome/fontawesome-svg-core'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import '@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS
import { Inter } from 'next/font/google'
import 'styles/main.scss'
config.autoAddCss = false

const inter = Inter({ subsets: ['latin'] })

const MyApp = ({ Component, pageProps, err }) => {
  const [cookies] = useCookies(['airshaper_consent'])
  const [isConsentGiven, setIsConsentGiven] = useState(false)

  useEffect(() => {
    if (cookies.airshaper_consent) {
      setIsConsentGiven(true)
    }
  }, [])
  return (
    <main className={inter.className}>
      <DefaultSeo {...seo} />
      {isConsentGiven && (
        <GoogleAnalytics trackPageViews gaMeasurementId="G-QGTPSTP8ST" />
      )}
      <Component {...pageProps} err={err} />
      <SocialProfileJsonLd
        type="Organization"
        name="AirShaper"
        url="https://airshaper.com"
        sameAs={[
          'https://www.facebook.com/AirShaper-868711716610088',
          'https://x.com/Air_Shaper',
          'https://www.linkedin.com/company/airshaper/',
        ]}
      />
    </main>
  )
}

export default MyApp
